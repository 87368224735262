<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.49867 7.54348V16.2032L12 20.6723L19.5013 16.2032V7.78579L11.9754 3.31169L4.49867 7.54348ZM12 1L2.5 6.37696V17.34L12 23L21.5 17.34V6.64765L12 1Z"
    />
  </svg>
</template>
